import { createSlice } from '@reduxjs/toolkit';
import getActivePhones from '../../utils/activePhones';

import { createAccessoryFilters } from './utils/createAccessoryFilters';
import { updateAccessoryFilters } from './utils/updateAccessoryFilters';

export const initialState: {
  filters: Record<string, any>[];
  sorters: Record<string, any>[];
  sortedItems: Record<string, any>[];
  accessories: Record<string, any>[];
  activePhones: Record<string, any>[];
  selectedPhone: Record<string, any> | null;
  activeFiltersCount: number;
  isLoading: boolean;
  search: string;
} = {
  filters: [],
  sorters: [],
  sortedItems: [],
  accessories: [],
  activePhones: [],
  selectedPhone: null,
  activeFiltersCount: 0,
  isLoading: true,
  search: '',
};

export const accessoryFiltersSlice = createSlice({
  name: 'Accessory_Filters',
  initialState,
  reducers: {
    initFilters: () => initialState,
    createFilters: (state, action) => {
      const {
        filterNames,
        accessories,
        phones,
      }: {
        filterNames: Record<string, any>[];
        accessories: Record<string, any>[];
        phones: Record<string, any>[];
        phoneBrands: Record<string, any>;
      } = action.payload;

      const activePhones = getActivePhones(phones);

      const { filters, sorters, sortedItems } = createAccessoryFilters(
        filterNames,
        accessories,
        activePhones,
      );

      return {
        ...state,
        filters,
        sorters,
        sortedItems,
        accessories,
        activePhones,
        selectedPhone: null,
        activeFiltersCount: 0,
        isLoading: false,
        search: '',
      };
    },
    updateFilters: (state, action) => {
      const {
        filters,
        sorters,
        selectedPhone,
        search,
      }: {
        filters: Record<string, any>[];
        sorters: Record<string, any>[];
        selectedPhone: Record<string, any> | null;
        search: string;
      } = action.payload;

      const activeFiltersCount = [...filters, ...sorters]
        .filter((filter) => filter.id !== 'phone')
        .reduce(
          (acc, current) => acc + current.options.filter((option) => option.enabled).length,
          0,
        );

      const { sortedItems } = updateAccessoryFilters(
        state.accessories,
        filters,
        sorters,
        selectedPhone,
      );

      return {
        ...state,
        filters,
        sorters,
        sortedItems,
        activeFiltersCount,
        selectedPhone,
        isLoading: false,
        search,
      };
    },
    clearFilters: (state, action) => {
      const {
        filters,
        sorters,
      }: {
        filters: Record<string, any>[];
        sorters: Record<string, any>[];
      } = action.payload;

      const clonedFilters = structuredClone(filters);
      const clonedSorters = structuredClone(sorters);

      clonedFilters.forEach(({ options }) => options.forEach((option) => {
        option.enabled = false;
      }));

      clonedSorters.forEach(({ options }) => options.forEach((option) => {
        option.enabled = false;
      }));

      return {
        ...state,
        filters: clonedFilters,
        sorters: clonedSorters,
        sortedItems: state.accessories,
        selectedPhone: null,
        activeFiltersCount: 0,
        isLoading: false,
      };
    },
    clearPhoneFilter: (state, action) => {
      const {
        filters,
      }: {
        filters: Record<string, any>[];
      } = action.payload;

      const clonedFilters = structuredClone(filters);

      clonedFilters.forEach((filter) => filter.options.forEach((option) => {
        if (filter.id === 'phone') {
          option.enabled = false;
        }
      }));
      const { sortedItems } = updateAccessoryFilters(
        state.accessories,
        clonedFilters,
        state.sorters,
        null,
      );

      return {
        ...state,
        filters: clonedFilters,
        sortedItems,
        selectedPhone: null,
        isLoading: false,
      };
    },
    setSelectedPhone: (state, action) => ({
      ...state,
      selectedPhone: action.payload,
      isLoading: false,
    }),
    updateLoadingState: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
});

export const {
  initFilters,
  createFilters,
  updateFilters,
  clearFilters,
  clearPhoneFilter,
  setSelectedPhone,
  updateLoadingState,
} = accessoryFiltersSlice.actions;

export default accessoryFiltersSlice.reducer;
