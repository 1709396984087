/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'regenerator-runtime/runtime';
import {
  onChatOpened,
  onChatClosed,
  onAdaReady,
  dismissAda,
} from '@pcf-engineering/gatsby-plugin-pcs-ada-chatbot';
import { isKiosk } from './src/utils/isKiosk';

import rootElement from './src/wrap-root-element';
import wrapPageIntl from './src/utils/wrapPageIntl';

export const wrapRootElement = rootElement;

/* Log analytics events when a user opens or closes the chat widget */
const AdaEvent = (event, widgetStatus) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event, widgetStatus });
  } catch (err) {
    console.warn(`Could not log AdaEvent: ${err}`);
  }
};

export const onInitialClientRender = () => {
  onAdaReady(() => {
    // Hide ada chatbot for kiosk
    if (isKiosk()) {
      dismissAda();
    }
  });
  onChatOpened(() => AdaEvent('AdaChatbotOpen', 'opened'));
  onChatClosed(() => AdaEvent('AdaChatbotClose', 'closed'));
};

export const wrapPageElement = wrapPageIntl;

export const onClientEntry = () => {
  const isKioskMode = isKiosk();
  if (isKioskMode) {
    const css = `
      #onetrust-banner-sdk, #onetrust-pc-sdk {
        display: none !important;
      }
    `;
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    document.documentElement.appendChild(style);
  }
};
