import React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import { Provider } from 'react-redux';
import * as createStore from './redux/createStore';

const config = {
  url: process.env.UNLEASH_URL,
  clientKey: process.env.UNLEASH_TOKEN,
  refreshInterval: 1800,
  appName: 'tms-publicsite',
  environment: process.env.UNLEASH_ENVIRONMENT,
};

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const { store } = createStore.default();
  return (
    <Provider store={store}>
      <FlagProvider config={config}>
        {element}
      </FlagProvider>
    </Provider>
  );
};
